import React, { useEffect, useState } from "react";
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";

const AppHeader = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        elevation={0}
        style={{ background: "transparent" }}
        position="fixed"
      >
        <Toolbar>
          <Link to="/">
            <Typography style={{ color: "white" }}>FUND ROCKET</Typography>
          </Link>
          <div style={{ flexGrow: 1 }} />
          <div style={{ width: 30 }} />
          <a
            href="https://lotto-lego.gitbook.io/lottolego/"
            style={{ color: "white" }}
            target="_blank"
          >
            GITHUB
          </a>
          <div style={{ width: 30 }} />
          <a
            href="https://lotto-lego.gitbook.io/lottolego/"
            style={{ color: "white" }}
            target="_blank"
          >
            GITBOOK
          </a>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default AppHeader;
