import React, { useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { createStructuredSelector } from "reselect";
import { makeSelectWeb3 } from "../../containers/Web3Wrapper/selectors";
import { makeSelectConnectedWallet } from "../../containers/ConnectedWalletWrapper/selectors";
import { connect } from "react-redux";
import { connectWeb3 } from "../../containers/Web3Wrapper/actions";
import { makeSelectVentureBoosterContract } from "../../containers/ContractsWrapper/selectors";

const CreateProjectScreen = ({
  connectedWallet,
  web3,
  dispatch,
  ventureBoosterContract,
}) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [milestones, setMilestones] = useState([]);

  const handleCreateProjectAsync = async () => {
    if (!web3) {
      dispatch(connectWeb3());
      return;
    }

    console.log(milestones);

    try {
      await ventureBoosterContract.methods
        .createProject(title, description, milestones)
        .send({ from: connectedWallet });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container maxWidth="sm">
      <br />
      <br />
      <br />
      <Card style={{ backgroundColor: "#FFF" }}>
        <CardHeader title="Create Project" />
        <form onSubmit={handleCreateProjectAsync}>
          <CardContent>
            <TextField
              label="Project Name"
              required
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <br />
            <br />
            <TextField
              multiline
              label="Description"
              required
              fullWidth
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </CardContent>
        </form>
      </Card>
      <br />
      {milestones.map((ms, index) => (
        <div key={`MS-${index}`}>
          <Card>
            <CardHeader title={ms.title || `Milestone ${index + 1}`} />
            <CardContent>
              <TextField
                fullWidth
                label="Title"
                onChange={(e) => {
                  milestones[index].title = e.target.value;
                }}
              />
              <br />
              <br />
              <TextField
                fullWidth
                label="Description"
                onChange={(e) => {
                  milestones[index].desc = e.target.value;
                }}
              />
              <br />
              <br />
              <TextField
                fullWidth
                type="number"
                label="Minimum"
                onChange={(e) => {
                  milestones[index].minimum = parseInt(e.target.value);
                }}
              />
              <br />
              <br />
              <TextField
                type="number"
                fullWidth
                label="Cap"
                onChange={(e) => {
                  milestones[index].cap = parseInt(e.target.value);
                }}
              />
              <br />
              <br />
              <TextField
                fullWidth
                label="NFT Path"
                onChange={(e) => {
                  milestones[index].tokenUri = e.target.value;
                }}
              />
            </CardContent>
          </Card>
          <br />
        </div>
      ))}
      <Card>
        <CardActions>
          <Button
            onClick={() =>
              setMilestones(
                milestones.concat([
                  {
                    title: "",
                    desc: "",
                    cap: 0,
                    minimum: 0,
                    deadline: 0,
                    tokenUri: "",
                  },
                ])
              )
            }
            type="submit"
            size="large"
            variant="contained"
            fullWidth
          >
            ADD MILESTONE
          </Button>
        </CardActions>
      </Card>
      <br />
      <Card>
        <CardActions>
          <Button
            onClick={handleCreateProjectAsync}
            type="submit"
            size="large"
            variant="contained"
            fullWidth
          >
            Create Project
          </Button>
        </CardActions>
      </Card>
      <br />
      <br />
      <br />
      <br />
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  web3: makeSelectWeb3(),

  connectedWallet: makeSelectConnectedWallet(),

  ventureBoosterContract: makeSelectVentureBoosterContract(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProjectScreen);
