import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  Container,
} from "@mui/material";
import { createStructuredSelector } from "reselect";
import { makeSelectWeb3 } from "../../containers/Web3Wrapper/selectors";
import { makeSelectConnectedWallet } from "../../containers/ConnectedWalletWrapper/selectors";
import { makeSelectVentureBoosterContract } from "../../containers/ContractsWrapper/selectors";
import { connect } from "react-redux";
import { connectWeb3 } from "../../containers/Web3Wrapper/actions";
import { useHistory } from "react-router-dom";

const ProjectsScreen = ({ ventureBoosterContract, dispatch }) => {
  const [projects, setProjects] = useState([]);

  const history = useHistory();

  useEffect(() => {
    if (!ventureBoosterContract) return;

    (async () => {
      const _projects = await ventureBoosterContract.methods
        .listProjects()
        .call();

      setProjects(_projects);
    })();
  }, [ventureBoosterContract]);

  console.log(projects);

  return (
    <Container maxWidth="sm">
      <br />
      <br />
      <br />
      {!ventureBoosterContract && (
        <Button
          color={"secondary"}
          variant="outlined"
          onClick={() => dispatch(connectWeb3())}
        >
          CONNECT WALLET
        </Button>
      )}
      {projects.map((project) => (
        <div key={project.projectName}>
          <Card>
            <CardHeader title={project.projectName} />
            <CardActions>
              <Button
                fullWidth
                variant="contained"
                onClick={() => history.push(`projects/${project.projectId}`)}
              >
                VIEW
              </Button>
            </CardActions>
          </Card>
        </div>
      ))}
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  web3: makeSelectWeb3(),

  connectedWallet: makeSelectConnectedWallet(),

  ventureBoosterContract: makeSelectVentureBoosterContract(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsScreen);
