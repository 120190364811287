import { createSelector } from "reselect";

const selectContracts = (state) => state.contracts;

const makeSelectVentureBoosterContract = () =>
  createSelector(
    selectContracts,

    (contractState) => contractState.ventureBoosterContract
  );

export { makeSelectVentureBoosterContract };
