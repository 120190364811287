import { fromJS } from "immutable";
import { SET_CONNECTED_WALLET } from "./constants";

const initialState = fromJS({
  connectedWallet: null,
});

function connectedWalletReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CONNECTED_WALLET:
      return {
        ...state,
        connectedWallet: action.connectedWallet,
      };

    default:
      return state;
  }
}

export { connectedWalletReducer };
