import React from "react";
import "../../App.css";
import "../../Mobile.css";
import AppRouter from "../../router";
import Web3Wrapper from "../Web3Wrapper";
import ContractsWrapper from "../ContractsWrapper";

const App = () => {
  return (
    <div className="App">
      <Web3Wrapper>
        <ContractsWrapper>
          <AppRouter />
        </ContractsWrapper>
      </Web3Wrapper>
    </div>
  );
};

export default App;
