import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1f0744",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#fff",
    },
  },
  overrides: {
    MuiPaper: {
      palette: {
        backgroundColor: "red",
      },
      root: {
        backgroundColor: "#000",
        overflow: "visible",
      },
    },
  },
  typography: {
    h1: {
      fontFamily: [
        "Nyata",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "@media (max-width:600px)": {
        fontSize: "3rem",
      },
    },

    h5: {
      "@media (max-width:600px)": {
        fontSize: "1.3rem",
      },
    },

    fontFamily: "Nyata",
    palette: {
      textPrimary: {
        // main: "white",
      },
    },
  },
});

export default theme;
