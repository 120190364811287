import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import reducer from "./reducers";
import connectedWalletWrapper from "./containers/ConnectedWalletWrapper/sagas";
import Web3Wrapper from "./containers/Web3Wrapper/sagas";
import ContractsWrapper from "./containers/ContractsWrapper/sagas";

const sagaMiddleware = createSagaMiddleware();

const store = createStore(reducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(connectedWalletWrapper);
sagaMiddleware.run(Web3Wrapper);
sagaMiddleware.run(ContractsWrapper);

export default store;
