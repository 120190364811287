import { put, takeLatest, all } from "redux-saga/effects";
import { setVentureBoosterContract } from "./actions";
import VentureBoosterContract from "../../contracts/localhost/VentureBooster.json";
import { SET_WEB3 } from "../Web3Wrapper/constants";

export function* connectTreasuryBoosterContractSaga({ web3 }) {
  try {
    if (!web3) return;

    const ventureBoosterContractInstance = new web3.eth.Contract(
      VentureBoosterContract.abi,
      VentureBoosterContract.address
    );

    yield put(setVentureBoosterContract(ventureBoosterContractInstance));
  } catch (err) {
    console.log(err);
  }
}

export function* connectTreasuryBoosterContractWatcher() {
  yield takeLatest(SET_WEB3, connectTreasuryBoosterContractSaga);
}

export default function* rootConnectedContractsSaga() {
  yield all([connectTreasuryBoosterContractWatcher()]);
}
