import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const HomeScreen = ({ dispatch }) => {
  return (
    <div>
      <div id="galaxy">
        <img src={require("../../assets/moon.png")} alt="Moon" />
        <div className="stars" />
        <div className="twinkling" />
        <div className="clouds" />
      </div>
      <div style={{ position: "relative", zIndex: 2 }}>
        <div className="hp-block full wave-bottom">
          <div
            style={{
              width: "100%",
              maxWidth: 675,
            }}
          >
            <img
              alt="rocket"
              style={{ width: 85 }}
              src={require("../../assets/rocket.png")}
            />
            <Typography variant="h1">FUND ROCKET</Typography>
            <Typography variant="h5">Fund your projects with NFTS</Typography>
          </div>
        </div>
        <div className="hp-block">
          <img className="waaves" src={require("../../assets/wave.svg")} />
          <Typography variant="h3">FUND YOUR PROJECT</Typography>
          <Grid container>
            <Grid item sm={4} xs={12}>
              <div
                className="booster"
                style={{
                  backgroundImage: `url(${require("../../assets/ws_bets.webp")})`,
                }}
              />
              <Typography className="booster-title" variant="h5" paragraph>
                RAFFLE BOOSTER
              </Typography>
              <ul>
                <li>
                  <Typography>
                    Set ticket sales minimum to ensure funding goals met
                  </Typography>
                </li>
                <li>
                  <Typography>Set deadlines</Typography>
                </li>
                <li>
                  <Typography>
                    Deposit NFTS as prizes. More text to make this two lines
                  </Typography>
                </li>
              </ul>
              <Button color="secondary" variant="outlined">
                WEN? SOON
              </Button>
            </Grid>
            <Grid item sm={4} xs={12}>
              <div
                className="booster"
                style={{
                  backgroundImage: `url(${require("../../assets/window.webp")})`,
                }}
              />
              <Typography className="booster-title" variant="h5" paragraph>
                VENTURE BOOSTER
              </Typography>
              <ul>
                <li>
                  <Typography>Raise funds with cap or no cap.</Typography>
                </li>
                <li>
                  <Typography>
                    Enable milestone based funding to increase investor
                    confidence.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Allow pro rata investment rights for early investors.
                  </Typography>
                </li>
              </ul>
              <Link to="/venture-booster/create">
                <Button color="secondary" variant="outlined">
                  GET STARTED
                </Button>
              </Link>
            </Grid>
            <Grid item sm={4} xs={12}>
              <div
                className="booster"
                style={{
                  backgroundImage: `url(${require("../../assets/booster.webp")})`,
                }}
              />
              <Typography className="booster-title" variant="h5" paragraph>
                CHARITY BOOSTER
              </Typography>
              <ul>
                <li>
                  <Typography>
                    Cap funding or leave open to continually accept donations.
                  </Typography>
                </li>
                <li>
                  <Typography>Permissionless funding</Typography>
                </li>
                <li>
                  <Typography>
                    Another bullet point coming soon. More text to make this two
                    lines.
                  </Typography>
                </li>
              </ul>
              <Button variant="outlined" color="secondary">
                WEN? SOON
              </Button>
            </Grid>
          </Grid>
        </div>
        <div className="hp-block">
          <Typography variant="h3">ROADMAP</Typography>
          <Grid container>
            <Grid item sm={4} xs={12}>
              <div
                className="booster"
                style={{
                  backgroundImage: `url(${require("../../assets/booster.webp")})`,
                }}
              />
              <Typography className="booster-title" variant="h5" paragraph>
                LAUNCH
              </Typography>
              <Typography>Deploy contracts</Typography>
            </Grid>
            <Grid item sm={4} xs={12}>
              <div
                className="booster"
                style={{
                  backgroundImage: `url(${require("../../assets/booster.webp")})`,
                }}
              />
              <Typography className="booster-title" variant="h5" paragraph>
                TOKENS
              </Typography>
              <Typography>Distribute tokens</Typography>
            </Grid>
            <Grid item sm={4} xs={12}>
              <div
                className="booster"
                style={{
                  backgroundImage: `url(${require("../../assets/booster.webp")})`,
                }}
              />
              <Typography className="booster-title" variant="h5" paragraph>
                TREASURY
              </Typography>
              <Typography>Manage revenue</Typography>
            </Grid>
          </Grid>
        </div>
        <div className="hp-block">
          <Typography variant="h3" paragraph>
            TEAM
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <div
                className="anon"
                style={{
                  backgroundImage: `url(https://marketplace.treasure.lol/_next/image?url=https%3A%2F%2Fipfs.io%2Fipfs%2FQmY71ban6QoWg9nbNwikk6wVWknj8NFBG8nMGHEuzwfAwf%2F2449%2F5.png&w=1920&q=75)`,
                }}
              />
              <Typography variant="h5">0xBOOST</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div
                className="anon"
                style={{
                  backgroundImage: `url(https://marketplace.treasure.lol/_next/image?url=https%3A%2F%2Fipfs.io%2Fipfs%2FQmR87K1oY8dXL4op91A9zcz4hPmCd8JbMVDTTuUnpXyQcr%2F5544%2F0.png&w=1920&q=75)`,
                }}
              />
              <Typography variant="h5">69xBOOST</Typography>
            </Grid>
          </Grid>
        </div>
      </div>
      <div
        style={{
          padding: 24,
          backgroundColor: "#1f0744",
          position: "relative",
          zIndex: 2,
        }}
      >
        <Typography style={{ color: "white" }}>fundrocket.xyz</Typography>
      </div>
    </div>
  );
};

export default HomeScreen;
