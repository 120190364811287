import React, { useEffect, useState } from "react";
import { Button, Container, Typography } from "@mui/material";
import { createStructuredSelector } from "reselect";
import { makeSelectWeb3 } from "../../containers/Web3Wrapper/selectors";
import { makeSelectConnectedWallet } from "../../containers/ConnectedWalletWrapper/selectors";
import { makeSelectVentureBoosterContract } from "../../containers/ContractsWrapper/selectors";
import { connect } from "react-redux";
import { connectWeb3 } from "../../containers/Web3Wrapper/actions";

const VentureProjectScreen = ({
  ventureBoosterContract,
  dispatch,
  projectId,
}) => {
  const [project, setProject] = useState(null);

  useEffect(() => {
    if (!ventureBoosterContract) return;

    (async () => {
      const _project = await ventureBoosterContract.methods
        .getProject(projectId)
        .call();

      setProject(_project);
    })();
  }, [ventureBoosterContract]);

  return (
    <Container>
      <br />
      <br />
      <br />
      {!ventureBoosterContract && (
        <Button
          color={"secondary"}
          variant="outlined"
          onClick={() => dispatch(connectWeb3())}
        >
          CONNECT WALLET
        </Button>
      )}
      {project && (
        <div>
          <Typography variant="h3">{project.projectName}</Typography>
        </div>
      )}
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  web3: makeSelectWeb3(),

  connectedWallet: makeSelectConnectedWallet(),

  ventureBoosterContract: makeSelectVentureBoosterContract(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VentureProjectScreen);
