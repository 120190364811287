import { fromJS } from "immutable";
import { SET_WEB3 } from "./constants";

const initialState = fromJS({
  web3: null,
});

function web3Reducer(state = initialState, action) {
  switch (action.type) {
    case SET_WEB3:
      return {
        ...state,
        web3: action.web3,
      };

    default:
      return state;
  }
}

export { web3Reducer };
