import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomeScreen from "../screens/HomeScreen";
import React from "react";
import CreateProjectScreen from "../screens/CreateProjectScreen";
import AppHeader from "../containers/AppHeader";
import ProjectsScreen from "../screens/ProjectsScreen";
import VentureProjectScreen from "../screens/VentureProjectScreen";

const AppRouter = () => {
  return (
    <Router>
      <AppHeader />
      <Switch>
        <Route exact path="/">
          <HomeScreen />
        </Route>
        <Route
          exact
          path="/venture-booster/create"
          render={() => <CreateProjectScreen />}
        />
        <Route
          exact
          path="/venture-booster/projects"
          render={() => <ProjectsScreen />}
        />

        <Route
          exact
          path="/venture-booster/projects/:id"
          render={(e) => <VentureProjectScreen projectId={e.match.params.id} />}
        />
      </Switch>
    </Router>
  );
};

export default AppRouter;
