import { fromJS } from "immutable";
import { SET_VENTURE_BOOSTER_CONTRACT } from "./constants";

const initialState = fromJS({
  ventureBoosterContract: null,
});

function contractsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_VENTURE_BOOSTER_CONTRACT:
      return {
        ...state,
        ventureBoosterContract: action.ventureBoosterContract,
      };

    default:
      return state;
  }
}

export { contractsReducer };
