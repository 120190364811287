import { createSelector } from "reselect";

const selectContracts = (state) => state.web3;

const makeSelectWeb3 = () =>
  createSelector(
    selectContracts,

    (web3) => web3.web3
  );

export { makeSelectWeb3 };
